<template>
  <content-loader
    :height="400"
    :width="500"
    :speed="3"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
  >
    <rect x="386.86" y="290.85" rx="0" ry="0" width="200" height="4.2" transform="rotate(294.99, 386.86, 290.85)"/>
    <rect x="288.78" y="228.05" rx="0" ry="0" width="120" height="4.2" transform="rotate(30.35, 288.78, 228.05)"/>
    <rect x="27.05" y="346" rx="0" ry="0" width="179" height="4" transform="rotate(301.49, 27.05, 346)"/>
    <rect x="216.52" y="259.34" rx="0" ry="0" width="80" height="4" transform="rotate(-23.49, 216.52, 259.34)"/>
    <rect x="120.8" y="193.33" rx="0" ry="0" width="120" height="4" transform="rotate(33.5, 120.8, 193.33)"/>
    <rect x="11.33" y="359.92" rx="0" ry="0" width="494" height="7"/>
  </content-loader>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import Placeholder from './Placeholder'

export default {
  components: {
    ContentLoader
  },
  extends: Placeholder
}
</script>
